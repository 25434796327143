.componentWrapper {
  --dx: 0px;
  --dy: 0px;
  --z-index: auto;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(var(--dx), var(--dy));

  z-index: var(--z-index);
}
