@use '../../Utils/Colors/Colors.module.scss' as colors;

.button {
  position: absolute;
  top: 0px;
  height: 41px;
  z-index: 1;
  margin: 0 !important;
}

[direction='right'] {
  right: 0px;
  background: linear-gradient(to left, colors.$white 45%, rgba(255, 255, 255, 0.4)) !important;
}

[direction='left'] {
  left: 0px;
  background: linear-gradient(to right, colors.$white 45%, rgba(255, 255, 255, 0.4)) !important;
}
