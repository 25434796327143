/*******************************
         Site Overrides
*******************************/

.ui {
    /* 
    Smooth out the edges of the font
    */
    -webkit-font-smoothing: antialiased;

    white-space: pre-line;
}

/* 
    Override h5, h4 and h3 font weight to 400, h2 and h1 should stay at 500
*/
    h5{
       font-weight:@normal !important;
    }

    h4{
        font-weight:@normal !important;
    }

    h3{
        font-weight:@normal !important;
    }

/* 
    Override body
*/
    body {
    overflow: auto;
    }