@use '../../Utils/Colors/Colors.module.scss' as color;
.componentWrapper {
  --border-width: 0.25em;
  --background-color: color.$black;
  --font-size: 1em;

  font-size: var(--font-size);

  position: relative;
  display: grid;
  place-content: center;

  width: 2em;
  height: 2em;
  border-radius: 50%;

  color: color.$white;
  box-shadow: 0 0 0px var(--border-width) color.$white;
  margin: var(--border-width);
  overflow: hidden;

  &[data-spinning='false'] {
    cursor: pointer;
  }

  // Pseudo element for background color.
  // Enables setting opacity for background when using color from variable (--border-width).
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    z-index: -1;
  }

  &[data-semi-transparent='true'] {
    &::before {
      opacity: 0.7;
    }

    // Show 100% opaque background color when hovering the badge.
    &:hover::before {
      opacity: 1;
    }
  }
}
