.checkboxWrapper {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 10px;
}

.dateRangeWrapper {
  align-items: center;
}
