/*******************************
         Site Overrides
*******************************/

.ui.modal {
    // "will-change" blocks "position:fixed" from positioning relative the view port (browser window).
    // ModalDropdownRenderer component requires "position:fixed" relative the view port.
    will-change: initial !important;

    .modal-dropdown-renderer .dropdown.scrolling {
        transform: none !important;
    }

    // To prevent cases where fullscreen modals take the full width of a widescreen monitor.
    max-width: calc(~'2560px - 5%');

    // Now that we limit the minimum size of the screen we need to prevent the modal from scaling down further when the screen is decreased.
    min-width:  fit-content;

    // To resolve the issue where the modal would be aligned to the left described in dimmer.overrides, we change
    // the position to relative and add auto margins to center.
    // https://bhch.github.io/posts/2021/04/centring-flex-items-and-allowing-overflow-scroll/
    position:relative;
    margin-left: auto !important;
    margin-right: auto !important;
    
}