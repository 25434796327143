@use '../../Utils/Colors/Colors.module.scss' as colors;
.dropContainer {
  height: 150px;
  background-color: colors.$offWhite;
  border: 2px dashed colors.$grey;
  display: flex;
  border-radius: 5px;
}

.dropContainerContent {
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
