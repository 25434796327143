.dropdown {
  [class='visible menu transition'] {
    max-height: 200px !important;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}
