.container {
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
  align-items: flex-start;
}

.headerWrapper {
  display: flex;
  gap: 10px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
}

.headerContent {
  margin-bottom: 0px !important;
  white-space: nowrap;
}

.headerInfoLabel {
  margin-bottom: 10px !important;
}

.headerInfoContent {
  white-space: nowrap;
}

.rightComponent {
  flex: 0 0 10%;
  display: flex;
  justify-content: flex-end;
  height: fit-content;
}
