@use '../../../Utils/Colors/Colors.module.scss' as colors;

.card {
  width: 100% !important;
  height: 100%;
}
.cardHeader {
  color: colors.$grey3 !important;
  margin-bottom: 0em !important;
}
.cardContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
}

.alternateLayoutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tooltipCard {
  width: fit-content !important;
}

.tooltipCardContent {
  display: flex;
  gap: 0.25em;
  align-items: center;
}

.legendContainer {
  display: flex;
  gap: 0.5em;
  align-items: center;
  font-size: 12px;
  width: 100%;
  font-weight: 300;
  justify-content: space-between;
}

.legendItem {
  display: flex;
  gap: 0.25em;
  align-items: center;
}

@media only screen and (min-width: 1200px) and (max-width: 1320px) {
  .legendContainer {
    flex-direction: column;
  }
}
