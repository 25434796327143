@use '../../../Utils/Colors/Colors.module.scss' as colors;
//Commented out code is for the dark style.
.fullscreenControl {
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
  button {
    margin: 0px !important;
  }
}
