@use '../../Utils/Colors/Colors.module.scss' as colors;

.statusCellWithIcon {
  display: flex;
  align-items: center;

  *:first-child {
    flex: 1;
  }

  .icon {
    height: auto !important;
    margin: 0 0 0 10px;
    cursor: pointer;
  }
}
.statusCellText {
  color: colors.$grey;
  font-style: italic;
}
