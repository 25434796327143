.table {
  border: none !important;
  tr {
    th {
      border-bottom: 0px !important;
      padding: 0.5em !important;
    }
    td {
      padding: 0.5em !important;
    }
  }
}
