@use '../../../Utils/Colors/Colors.module.scss' as colors;

.container {
  display: flex;
  gap: 0.5em;
  align-items: center;
  flex-wrap: wrap;
}

.dropdownMenu {
  min-width: fit-content !important;
}

.compactDropdown {
  [class~='visible'] {
    min-width: fit-content !important;
  }
}
