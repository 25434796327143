/*******************************
         Site Overrides
*******************************/

// Highlight rows of table regardless if they're selectable or not. Differentiate the selectable rows by changing the cursor to pointer
.ui.table {
    tbody {
      tr:hover {
        background-color: @transparentBlack;
      }
    }
  }
  
.ui.selectable.table tbody tr:hover,
.ui.table tbody tr td.selectable:hover {
  cursor: pointer;
}

.ui.table thead {
  font-size: @h5;
}


.ui.small.table thead{
  font-size: @h5;
}

.ui[class*="very basic"].table:not(.sortable):not(.striped) th:first-child, .ui[class*="very basic"].table:not(.sortable):not(.striped) td:first-child{
  padding-left: @5px;
}

.ui[class*="very basic"].table:not(.sortable):not(.striped) th:last-child, .ui[class*="very basic"].table:not(.sortable):not(.striped) td:last-child{
  padding-right: @5px;
}

.ui[class*="very basic"].table{
  border-bottom: @borderWidth solid @borderColor
}

.ui.sortable.table thead{
  pointer-events: none;
}

.ui.sortable.table thead [sortable="true"]{
  pointer-events: all;
}

.ui.sortable.table thead [sortable="true"]:after {
    font-family: "Icons";
    content: '\f0dc';
    display: inline-block;
    color: @mutedTextColor;
}

.ui.sortable.table thead th.sorted:after{
  color:@textColor;
}
