@use '../../Views/AppShell/scss/mixins';
@use '../../Utils/Colors/Colors.module.scss' as colors;

//
// Change font-size if you want to change box dimensions.
//
.componentWrapper {
  --boxSize: 7em;

  font-size: 9px;

  position: relative;
  width: var(--boxSize);
  height: var(--boxSize);
  min-height: var(--boxSize);
  min-width: var(--boxSize);

  // Change cursor to pointer for images.
  // (Images are clickable)
  &[data-image='true'] {
    cursor: pointer;
  }
}

//
// Wrapper for content that hides overflowed content.
//
.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;

  border-radius: 10%;
  overflow: hidden;

  // Background displayed when <img> size are set but content not fully loaded.
  @include mixins.chess-background(colors.$white, colors.$lightGrey);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  //
  // Actual image.
  //
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    // Show image above loader.
    // This will make loader disappear as soon as the image has been loaded.
    // (Image can be visible before img onLoad fires, depending on image size)
    position: relative;
    z-index: 2;
  }

  //
  // Make content semi-transparent when item has been marked for deletion.
  //
  &[data-marked-for-deletion='true'] {
    opacity: 0.5;
  }
}

//
// File extension displayed in bottom part of file box.
//
.extension {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  white-space: nowrap;

  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
  color: colors.$grey;
}

.fileName {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  white-space: nowrap;

  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
  color: colors.$grey;
}

.nonImageIcon {
  position: absolute;
  top: calc(25%);
  left: 50%;
  transform: translateX(-50%);
  font-size: 2em !important;
}
