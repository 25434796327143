/*******************************
        User Overrides
*******************************/

// Now that we limit the minimum size of the screen we need to handle overflow in the dimmer.
// To do this we set overflow-x to auto, but there's still an issue where decreasing the screen further
// ignores the overflow on the left side due to center alignment. For this we set the alignment to initial
// and then override it once the screen width is higher than the minimum screen width.

.ui.page.dimmer {
        align-items: initial;
        overflow-x: auto;
}

@media screen {
  @media (min-width: @minimumScreenWidth) {
    .ui.page.dimmer {
        align-items: center;
        }
  }
}