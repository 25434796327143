/*******************************
         Site Overrides
*******************************/

.ui.grid{
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}

.ui.grid > .column:not(.row){
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ui.grid > .column:not(.row), .ui.grid > .row > .column{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    
}