@use '../../../Utils/Colors/Colors.module.scss' as colors;

.zoomControl {
  cursor: pointer;
  margin-right: 7px;
  margin-bottom: 7px;
  margin-top: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
}
