$blue1: #007bff;
$blue2: #f4f7fc;
$black: #202020;
$red: #d22d09;
$grey: #838383;
$grey2: #dcdcdc;
$grey3: #949494;
$lightGrey: #f0f0f0;
$darkGrey: #666666;
$orange: #ec7f00;
$green: #28a745;
$lightGreen: #49b407;
$white: #ffffff;
$chat1: #f0f0f0;
$chat2: #c9ead4;
$lightYellow: #ffffe0;
$offWhite: #f2f2f2;
$chart1: #3e92cc;
$chart2: #83c265;
$chart3: #af7039;
$chart4: #16324f;
$chart5: #efa48b;
$chart6: #9d02d7;
$chart7: #d22d09;
$chart8: #3e91ccbb;
$chart9: #16324fbb;
$link: #0099ff;
$activeSearchBar: #333333;
$inactiveSearchBar: #3f3f3f;
$topMenuBackground: #272727;
$yellow: #f2c218;
$paleYellow: #e9eac9;
$paleGreen: #c9ead4;
$palePurple: #c9caea;

:export {
  blue1: $blue1;
  blue2: $blue2;
  black: $black;
  red: $red;
  grey: $grey;
  grey2: $grey2;
  grey3: $grey3;
  lightGrey: $lightGrey;
  darkGrey: $darkGrey;
  orange: $orange;
  green: $green;
  lightGreen: $lightGreen;
  white: $white;
  chat1: $chat1;
  chat2: $chat2;
  lightYellow: $lightYellow;
  offWhite: $offWhite;
  chart1: $chart1;
  chart2: $chart2;
  chart3: $chart3;
  chart4: $chart4;
  chart5: $chart5;
  chart6: $chart6;
  chart7: $chart7;
  chart8: $chart8;
  chart9: $chart9;
  link: $link;
  activeSearchBar: $activeSearchBar;
  inactiveSearchBar: $inactiveSearchBar;
  yellow: $yellow;
  paleYellow: $paleYellow;
  paleGreen: $paleGreen;
  palePurple: $palePurple;
}
