@use '../../../../../Utils/Colors/Colors.module.scss' as var;

.clusterControl {
  cursor: pointer;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-right: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 29.72px !important;
}
.checkboxMenu {
  flex: 1 !important;
  pointer-events: all;
  padding: 5px !important;
  height: 100%;
  display: flex !important;
  align-items: center;
}
