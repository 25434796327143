@use '../../../Utils/Colors/Colors.module.scss' as colors;
//
// CHECKBOX
//
// Green/orange/red background color.
//
@each $name, $color in ('red': colors.$red, 'orange': colors.$orange, 'green': colors.$green) {
  // Not checked
  .ui.checkbox:not(.checked).#{$name}-checkbox {
    opacity: 0.7;
    font-weight: normal !important;
  }

  // Checked
  .ui.checkbox.checked.#{$name}-checkbox label {
    &::before {
      background-color: $color !important;
    }
    &::after {
      color: colors.$white !important;
      font-size: 0.8em;
    }
  }
}

//
// FORM.INPUT
//
.field.readOnlyInput input {
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}
