.headerContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
  img {
    height: 50px;
    width: 50px;
  }
}

.paneContainer {
  overflow-y: auto;
  max-height: 50vh;
}

.form {
  [class*='disabled'] {
    opacity: 1 !important;
    input {
      border-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
