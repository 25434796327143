@use '../../Utils/Colors/Colors.module.scss' as colors;
.card {
  white-space: nowrap;
  width: 100% !important;
  height: 224px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
}

.cardContent::after {
  display: none !important;
}

.cardHeader {
  color: colors.$grey3 !important;
  margin-bottom: 0em !important;
}

.cardExtra {
  display: flex;
  gap: 0.25em;
  align-items: flex-end;
  white-space: nowrap;
  flex: 1;

  .extraIcon {
    background-color: colors.$lightGrey;
    box-shadow: none !important;
  }
}

.alternateLayoutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
