@use '../../Utils/Colors/Colors.module.scss' as colors;
//
// Change font-size if you want to change box dimensions.
//
.componentWrapper {
  width: 16em !important;
  height: 16em !important;

  // Change cursor to pointer for images.
  // (Images are clickable)
  &[data-image='true'] {
    cursor: pointer;
  }
}

//
// Wrapper for content that hides overflowed content.
//
.contentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
  }
}

.bottomContainer {
  height: 35%;
  width: 100%;
  background-color: colors.$white !important;
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 0.25em !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.bottomContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 5px;
}

.fileNameContent {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.fileNameInnerText {
  flex: 1;
  font-size: 1em;
  flex-wrap: wrap;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 1.15em; /* fallback */
  max-height: 3.45em; /* fallback */
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.bottomContainerContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  color: colors.$green;
  align-self: flex-start;
  margin-top: 10px !important;
}
