.tooltipWrapper {
  position: absolute;
  z-index: 1;
}

.hoverCard {
  width: fit-content !important;
}

.cardContent {
  display: flex;
  gap: 0.5em;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
}

.tooltipTable {
  border: none !important;
  tbody {
    tr {
      td {
        border: none !important;
      }
    }
  }
}

.boldRow {
  font-weight: 400;
}
