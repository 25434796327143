@use '../../../Utils/Colors/Colors.module.scss' as colors;

.componentWrapper {
  position: fixed;

  // Value large enough to display component above all other content.
  z-index: 2147483647;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
  user-select: none;
  animation: fadeIn ease-out 250ms;
}

.popup {
  position: fixed;
  width: 400px;
  max-height: calc(70vh - 2 * 60px);
  overflow: auto;
  border-radius: 5px;
  background-color: colors.$white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  animation: fadeIn ease-out 150ms;

  // Header
  header {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;

    position: sticky;
    top: 0;
    z-index: 1;

    h3 {
      margin: 0;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.menu {
  border: none !important;
  box-shadow: none !important;
  margin: 15px !important;
  //Take up the whole container width without causing overflow.
  width: calc(100% - 30px) !important;
}

.item {
  margin-top: 15px !important;
  border-radius: 5px !important;

  p {
    font-weight: 300;
  }
  // Red circle for unseen items.
  &[data-seen='false']::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: colors.$red;
  }
}

.item::before {
  top: -7.5px !important;
}

.alternateLayout {
  padding: 1rem;
  text-align: center;
}
