@use '../../../Utils/Colors/Colors.module.scss' as colors;

.card {
  min-width: fit-content;
  white-space: nowrap;
  width: 100% !important;
}
.cardHeader {
  color: colors.$grey3 !important;
  margin-bottom: 0em !important;
}

.cardValue {
  display: inline;
  font-size: 12px;
}

.valueContainer {
  line-height: 14px;
}

.valueSuffix {
  display: inline;
  padding-left: 0.25em;
  font-size: 12px;
}

.cardExtra {
  display: flex;
  gap: 0.25em;
  align-items: flex-end;

  .extraIcon {
    background-color: colors.$lightGrey;
    box-shadow: none !important;
  }
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
}

.cardContent::after {
  display: none !important;
}

.alternateLayoutContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
