@use '../../Utils/Colors/Colors.module.scss' as colors;

$small-padding: 5px;
$bottom-container-height: 90px;
$top-container-height: 40px;

.componentWrapper {
  // Overlay entire view port.
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  background: rgba(0, 0, 0, 0.25);

  // Value large enough to display component above all other content.
  z-index: 2147483647;

  // Center image
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  //Keep padding here same with centerContainer
  padding: $small-padding;
}

.badgesContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon {
  color: colors.$lightGrey;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  &:hover {
    color: colors.$white;
  }
}

.fileListContainer {
  align-self: center;
  justify-content: center;
  width: 30%;
  min-width: 150px;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 80px;
  max-height: 80px;
  min-height: 80px;
  display: flex;
  flex-wrap: nowrap;
}

.middleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-height: calc(100% - #{$bottom-container-height} - #{$top-container-height});
}

.fileTitle {
  color: white;
  font-size: 1.2em;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.topContainer {
  height: $top-container-height;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.controlIconsContainer {
  width: 70px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

  //Keep padding here same with componentWrapper
  padding: $small-padding;
}

.bottomContainer {
  width: 100%;
  height: $bottom-container-height;
  min-height: $bottom-container-height;
  display: flex;
  justify-content: center;
  align-items: center;
}
