.loadMoreWrapper {
  margin-top: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.tableWrapper {
  overflow-x: auto;
}
