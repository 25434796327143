.loadingContainer {
  margin-top: 1em;
  text-align: center;
}

.scrollableContainer {
  overflow-y: auto;
  max-height: 50vh;
  margin-top: 1em;
}
