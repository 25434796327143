@use '../../Utils/Colors/Colors.module.scss' as colors;

.componentWrapper {
  --size: 1em;
  --dy: 0px;

  position: absolute;
  top: calc(100% / 2 - var(--size) / 2 + var(--dy));
  left: calc(100% / 2 - var(--size) / 2);
  width: var(--size);
  height: var(--size);

  border: calc(var(--size) * 0.25) solid colors.$lightGrey;
  border-top-color: colors.$blue1;
  box-shadow: 0 0 0 1px colors.$grey, inset 0 0 0 1px colors.$grey;
  border-radius: 50%;

  z-index: 100;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
