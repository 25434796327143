@use '../../../Utils/Colors/Colors.module.scss' as colors;
.emptyRow {
  height: 42px;
}
.comparisonContent {
  font-size: 12px;
  font-weight: 300;
}

.popupContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerSuffix {
  padding-left: 0.25em;
  font-weight: 300;
  font-size: 12px;
}
.headerContainer {
  display: inline;
}

.tableWrapper {
  overflow-x: auto;
  margin-top: 1em;
}

.popupContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
}

.popupContent {
  display: flex;
  gap: 0.25em;
  align-items: flex-end;

  .popupIcon {
    background-color: colors.$lightGrey;
    box-shadow: none !important;
  }
}
