.header {
  margin-top: 1em;
  display: flex;
  gap: 10px;
  align-items: center;
}

.buttonIcon {
  width: 1em;
}

.input {
  input {
    width: 100% !important;
  }
}
