.label {
  height: 2em;
  width: 2em;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}

.labelsWrapper {
  width: fit-content !important;
  display: flex;
  align-items: center;
}

.emptyUserLabel {
  margin-top: 0 !important;
}
