/*******************************
         Site Overrides
*******************************/

// https://github.com/fomantic/Fomantic-UI/pull/48/files
.ui.segment.tab:last-child {
  margin-bottom: @verticalMargin;
}

// Also, add border top for all tab panes.
.ui.attached:not(.message) + .ui.attached.segment:not(.top) {
  border-top: @attachedBorder;
}

.ui.tiny.segment{
  font-weight:@lighter;
}