@use '../../Utils/Colors/Colors.module.scss' as var;

.mapContainer {
  width: 100%;
  height: 100%;
  min-height: 462px;

  //Workaround to style the street view control as the MapControlButton
  [class='gm-svpc'] {
    width: 36.41px !important;
    height: 34.53px !important;
    background-color: var.$white !important;
    left: -6px !important;
    border-radius: 5px !important;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25) !important;
  }
}
