.actionButton {
  box-shadow: none !important;
}

.headerImage {
  width: 200px;
  height: 115px;
  border-radius: 5px;
  object-fit: cover;
}

.headerExtraRow {
  display: flex;
  gap: 10px;
  align-items: center;
}

.address {
  font-size: 12px;
}
