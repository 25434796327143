@use '../../../../Utils/Colors/Colors.module.scss' as colors;

.contractInfoContainer {
  display: flex;
  gap: 2em;
  align-items: center;
  flex-wrap: wrap;
}

.tableContainer {
  flex: 1;
}

.table {
  margin-top: 0em !important;
  border: none !important;
  tr {
    td {
      border: none !important;
    }
  }
  tr:hover {
    background-color: transparent !important;
  }
}
.tableHeaderRow {
  font-weight: 400;
  td {
    padding-bottom: 0em !important;
  }
}

.borderlessTable {
  border-bottom: none !important;
}

.filesContainer {
  font-size: 12px;
}

.scrollingContainer {
  overflow-y: auto;
  max-height: 50vh;
}

.loaderContainer {
  display: flex;
  justify-content: center;
}

.header {
  display: flex !important;
  gap: 1em;
}

.middleSection {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  gap: 1em;
}

.tenants {
  flex: 1;
}

.areaDistribution {
  min-width: 260px;
}

.linkContainer {
  font-size: 14px;
  font-weight: 300;
  color: colors.$link;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.infoMessageWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  div {
    max-width: 600px;
  }
}

.lastRow {
  font-weight: 500;
}
