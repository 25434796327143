.tooltipWrapper {
  position: absolute;
  z-index: 1;
}

.hoverCard {
  width: fit-content !important;
}

.cardContent {
  display: flex;
  gap: 0.5em;
  white-space: nowrap;
}
