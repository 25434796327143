@use '../../../Utils/Colors/Colors.module.scss' as colors;
.searchWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .searchBar {
    [class='results transition visible'] {
      background-color: colors.$activeSearchBar !important;
      border: 1px solid colors.$inactiveSearchBar !important;
      padding: 5px;
      font-weight: 300;

      [class*='category'] {
        background-color: transparent !important;
      }
    }

    .categorySection {
      background-color: transparent !important;
      padding-bottom: 5px;

      .categoryTitle {
        padding: 10px !important;
        margin: 0px !important;
        display: flex;
        justify-content: space-between;
        a {
          font-weight: 300;
        }
      }

      .resultsSection {
        display: table-cell;
        width: 28em;
        background-color: transparent !important;
        border: none !important;

        [class*='result'] {
          padding: 10px !important;
          color: colors.$white !important;
          border-radius: 5px !important;
          border: none !important;
          white-space: nowrap;
          max-width: 380px;

          .itemText {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        [class='result']:hover {
          background-color: colors.$inactiveSearchBar !important;
        }
        [class='active result'] {
          background-color: colors.$inactiveSearchBar !important;
        }
      }
    }

    .input {
      input {
        background-color: colors.$inactiveSearchBar !important;
        border-color: colors.$inactiveSearchBar !important;
        caret-color: colors.$lightGrey;
        color: colors.$offWhite !important;
      }

      input:hover {
        background-color: colors.$activeSearchBar !important;
      }

      input:focus {
        background-color: colors.$activeSearchBar !important;
      }

      // Change highlighting color when selecting text in search
      input::selection {
        background-color: colors.$lightGrey !important;
      }

      //Change placeholder color when focused
      input:focus::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: colors.$offWhite !important;
        opacity: 1; /* Firefox */
      }

      input:focus:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: colors.$offWhite !important;
      }

      input:focus::-ms-input-placeholder {
        /* Microsoft Edge */
        color: colors.$offWhite !important;
      }

      i {
        color: colors.$offWhite !important;
      }
    }
  }
  .button {
    position: relative;
    height: 24px;
    width: 24px;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    margin-left: -2.15em !important;

    background-color: transparent !important;
    color: colors.$white !important;
  }
  .button:hover {
    background-color: colors.$inactiveSearchBar !important;
  }
}
