/*******************************
        User Overrides
*******************************/

.ui.search.dropdown > input.search {
   font-family: @fontName;
}

// To resolve the issue where the menu was 2px smaller than the input
.ui.scrolling.dropdown .menu{
    min-width:calc(100% + 2px) !important;
}


.ui.active.floating.selection.dropdown{
    border-radius: @defaultBorderRadius !important;

    .visible.menu{
        border-top-width:1px !important;

        .item{
            border-radius: @defaultBorderRadius !important;
        }
    }

}