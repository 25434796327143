@use '../../../Utils/Colors/Colors.module.scss' as colors;
.barContainer {
  border-top: 1px solid colors.$grey2;
  border-bottom: 1px solid colors.$grey2;
  display: flex;
}

.scrollableBarContainer {
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
  display: flex;
}
