.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
}

.listItemContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.listItemContentContainer {
  flex: 1;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.buttonContainer {
  flex-direction: row;
  display: flex;
  align-items: center;
}
