@use '../../../Utils/Colors/Colors.module.scss' as colors;
@use 'mixins';

:root {
  --header-height: 50px;
}

$minScreenWidth: 1034px;

#topMenu {
  position: sticky;
  top: 0;
  z-index: 2;
  margin: 0;
  border: none;
  border-radius: 0 !important;
  background-color: colors.$topMenuBackground;

  #appName {
    font-size: 24px;
    color: colors.$lightGrey;
    padding-left: 0.25em;
  }

  // Unlike Input, the SearchBar component is not designed to be used inside
  // the menu, which results in too big vertical paddings.
  &.ui.menu .search-menu-item {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  i.icon {
    // Semantic UI expects a label together with an icon and adds extra margin, which we don't need here.
    margin-right: 0 !important;

    // Place any circular label next to the icon in the upper right corner.
    & + label.circular {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-40%, 40%);
    }
  }
}

#shell-container {
  --max-width: 2560px;
  --main-padding: 1em;
  --main-overflow: hidden;
  // New stacking context.
  position: relative;
  z-index: 1; // Must be lower than <header> z-index.

  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'nav content';
  background-color: colors.$white;
  max-width: var(--max-width);

  nav.left-menu {
    position: sticky;
    top: var(--header-height);
    max-height: calc(100vh - #{var(--header-height)});
    max-width: 15rem;
    grid-area: nav;
    margin: 0;
    border-right: 1px solid colors.$grey2;
    padding: 0.25em;

    .header {
      display: flex;
      margin-bottom: 0;
      align-items: center;

      i.icon {
        margin-right: 10px;
      }

      label {
        margin-left: auto;
        font-size: 7pt !important;
      }
    }

    .item .menu .item:first-of-type {
      margin-top: 10px !important;
    }
    .item {
      margin: 1px 0px !important;
    }
  }

  main {
    grid-area: content;

    padding: var(--main-padding);
    // Make sure that background fills all visible space when there is not enough
    // content on the page.
    min-height: calc(100vh - #{var(--header-height)});
    // Ensure main container doesn't overflow to ensure nested scrollable components don't overflow.
    // An exception to this would be the FaultReportDetails page where we need it for the sticky right bar.
    overflow: var(--main-overflow);
  }
}

.no-line-break {
  white-space: nowrap;
}

.clear-icon {
  height: auto !important;
  margin: 0;
}

//
// Change cursor for disabled dropdown list items.
//
[role='listbox'] .item.disabled {
  cursor: not-allowed !important;
}

.containedWrapper {
  height: calc(100vh - #{var(--header-height)});
}

.profile-dropdown {
  .menu > .item {
    border-radius: 5px;
  }
}

#root-content {
  min-width: $minScreenWidth;
}
