@use '../../Utils/Colors/Colors.module.scss' as colors;

#fileUploadModal {
  tbody td {
    // Set min height to prevent height is changed when status
    // information is changed from text to progressbar.
    min-height: 50px;
  }

  .category-cell {
    display: flex;
    align-items: center;

    .icon.exclamation {
      margin-right: 10px;
      color: colors.$red;
    }

    .dropdown:nth-child(2) {
      font-size: 0.9em;
      color: colors.$grey;
    }
  }

  .type-dropdowns {
    display: flex;
    flex-direction: column;

    .default.text {
      color: colors.$grey !important;
    }
  }
}
