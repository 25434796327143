.button {
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25) !important;

  // Workaround to make sure that FA icons appear more vertically centered inside the button.
  line-height: 1.125em !important;
  :global {
    svg {
      vertical-align: -0.25em;
    }
  }
}
