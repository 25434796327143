.graphHeaderContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5em;
}

.headerGrid {
  padding-bottom: 0 !important;
}

.chartLegend {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.tableHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
