@use '../../Views/AppShell/scss/mixins';
@use '../../Utils/Colors/Colors.module.scss' as colors;

.componentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    // Background displayed when <img> size are set but content not fully loaded.
    @include mixins.chess-background(colors.$white, colors.$lightGrey);

    // Min width.
    min-width: 200px;
    min-height: 200px;
    max-height: 100%;
    max-width: 100%;

    // If image is smaller than 200x200, resize to fit but keep aspect ratio.
    object-fit: contain;
  }
}

//
// Container displayed when image was not successfully loaded.
//
.error {
  position: relative;
  background-color: colors.$white;
  padding: 20px;
  display: flex;
  flex-direction: column;

  span {
    display: block;
    margin-bottom: 20px;
  }
}
