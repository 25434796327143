@use '../../Utils/Colors/Colors.module.scss' as colors;

.componentWrapper {
  --upload-item-border-radius: 0.75em;
  --progress-text: '0%';
  --progress-value: 0%;
  --size: 7em;
  --loaderSize: calc(var(--size) * 0.35);

  position: relative;
  font-size: 9px;

  &[data-status='Uploaded'] {
    cursor: zoom-in;
  }
}

//
// Image wrapper
//
.imageWrapper {
  position: relative;
  border-radius: var(--upload-item-border-radius);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: colors.$lightGrey;
  background: repeating-conic-gradient(from 0, colors.$white 0deg 90deg, hsl(0, 0%, 97%) 90deg 180deg);
  background-size: 20px 20px;
  overflow: hidden;
  width: var(--size);
  height: var(--size);

  // Actual image.
  > img {
    width: var(--size);
    height: var(--size);
    object-fit: cover;
  }
}

// File extension.
.fileNameExtension {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  white-space: nowrap;

  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.2em;
  color: colors.$grey;
}

// Icon visible when file has been uploaded.
.uploadFinishedIcon {
  height: auto;
  position: absolute;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.4) !important; // Override Semantic "circular".
  left: 5px;
  top: 5px;
  font-size: 6pt;
  background-color: colors.$white;
}

.componentWrapper[data-status='ReadyForUpload'] .imageWrapper {
  img {
    opacity: 0.6;
  }
}

//
// Displays overlay with text and progress bar.
//
.componentWrapper[data-status='Uploading'] .imageWrapper {
  img {
    opacity: 0.2;
  }

  // Progressbar
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: var(--progress-value);
    height: 100%;
    background-color: colors.$green;
    opacity: 0.3;
    overflow: hidden;
  }

  // Text
  &::after {
    content: var(--progress-text);
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5em;
    font-weight: bold;
  }
}

//
// Error overlay
//
.error {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(160, 5, 0, 0.4);
  overflow: hidden;
  color: colors.$white;
  display: grid;
  place-content: center;
  font-size: 1.5em;
  cursor: pointer;
}
