.tooltipCard {
  width: fit-content !important;
}

.tooltipCardContent {
  display: flex;
  gap: 0.25em;
  align-items: center;
}

.pieContainer {
  height: 140px;
}
