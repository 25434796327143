@use '../../../Utils/Colors/Colors.module.scss' as colors;

//Commented out code is for the dark style.

.mapTypeControl {
  cursor: pointer;
  margin-left: 7px;
  margin-top: 7px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.optionCard {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  img {
    height: 40px;
  }
}

.dropdownMenu::after {
  display: none !important;
}

.dropdownMenu {
  border: none !important;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
  [class~='active'] {
    img {
      border: 2px solid colors.$blue1;
      border-radius: 20%;
    }
  }
}

.dropdownOption {
  font-size: 0.8rem !important;
}

.dropdownTrigger {
  height: 65px;
  width: 65px !important;
  margin-bottom: 0 !important;
  padding: 0.25em !important;
  img {
    object-fit: cover !important;
  }
}
//Workaround to show 2 dropdowns at the same time from the same trigger
.emptyTrigger {
  height: 65px;
  width: 0px !important;
}

.lastItem {
  padding-right: 0em !important;
  padding-top: 4px !important;
}
