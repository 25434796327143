@use '../../Utils/Colors/Colors.module.scss' as colors;

.datePicker {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.datePickerWrapper {
  width: 100%;
  [class='react-datepicker__close-icon']::after {
    background-color: colors.$red;
  }
}

.datePickerPopper {
  [class*='react-datepicker__day--selected'] {
    background-color: colors.$green;
  }
  [class*='react-datepicker__day--keyboard-selected'] {
    background-color: colors.$green;
  }

  [class*='react-datepicker__month-text--selected'] {
    background-color: colors.$green;
  }
  [class*='react-datepicker__month-text--keyboard-selected'] {
    background-color: colors.$green;
  }

  [class='react-datepicker'] {
    font-size: 12px;
    font-weight: 300;

    border-color: colors.$grey2;

    [class='react-datepicker__current-month'] {
      font-size: 1rem;
      color: colors.$black;
    }
  }

  [class='react-datepicker__header '] {
    background-color: colors.$white !important;
    border-bottom-color: colors.$grey2 !important;
  }

  [class='react-datepicker__header react-datepicker-year-header'] {
    background-color: colors.$white !important;
    border-bottom-color: colors.$grey2 !important;
  }

  [class='react-datepicker__triangle']::after {
    border-bottom-color: colors.$white !important;
  }

  [class='react-datepicker__triangle']::before {
    border-bottom-color: colors.$grey2 !important;
  }
}
