@use '../../Utils/Colors/Colors.module.scss' as colors;

.cardHeader {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-shadow: 1px 1px 1px colors.$black;
  position: absolute;
  margin: 0.5em !important;
  z-index: 1;
}

.labelsContainer {
  position: absolute;
  bottom: 0;
  margin-left: 0.5em;
}

.image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.imageContainer {
  padding: 0 !important;
  margin: 0 !important;
}

.compactContent {
  font-size: 12px !important;
  padding: 5px !important;
}

.compactExtra {
  font-size: 12px !important;
  padding: 5px !important;
  color: colors.$darkGrey !important;
}

.cardContainer {
  width: calc(20% - 14px) !important;
}

@media only screen and (max-width: 1300px) {
  .cardContainer {
    width: calc(33.333333333% - 14px) !important;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 1550px) {
  .cardContainer {
    width: calc(25% - 14px) !important;
  }
}

@media only screen and (max-width: 1048px) {
  .cardContainer {
    width: calc(50% - 14px) !important;
  }
}
