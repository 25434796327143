.horizontalTopMenu {
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.horizontalTopMenu::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.wrapper {
  position: relative;
}
