@use '../../../../Utils/Colors/Colors.module.scss' as var;

//Commented out code is for the dark theme of the InfoBoxes

.card {
  width: fit-content !important;
  max-width: 500px !important;
  font-size: 1rem;
  flex: 1;
  pointer-events: all;
}

.cardHeaderContainer {
  display: flex;
  justify-content: space-between;

  .headerIconContainer {
    display: flex;
    height: fit-content;
    margin-top: 0.25em;

    button {
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var.$white;
    }
    button:hover {
      background-color: var.$lightGrey;
    }
  }
}

.table {
  border: none !important;
  tr {
    td {
      background-color: transparent !important;
    }
  }
  tr:hover {
    background-color: transparent !important;
  }
}

.titleCell {
  color: var.$darkGrey !important;
  border: none !important;
}

.valueCell {
  border: none !important;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  display: flex;
  padding-right: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  justify-content: space-between;
}

.link {
  font-weight: 300;
}

.footerContent {
  color: var.$black !important;
}

.cardHeader {
  margin-top: 1em !important;
  margin-left: 1em !important;
  margin-bottom: 0 !important;
  word-break: break-all;
}
