.componentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  max-width: 100%;
  gap: 12px;
}

.filePlaceholder {
  width: 16em;
  height: 16em;
  border-radius: 5px;
  margin-top: 0em !important;
}
